import classNames from 'classnames';
import { Search } from 'components/home/Search';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { IBackgroundImages } from 'api/home/types';
import styles from './styles.module.scss';

export const Hero404 = ({ backgroundImage, loading }: { backgroundImage?: IBackgroundImages, loading?: boolean }) => {
  const { t } = useOTSTranslation('common');

  return (
    <div>
      <header className={styles.photo} style={!loading ? { backgroundImage: `url("${backgroundImage?.image}"` } : {}}>
        <div className={styles.background} />
        <div className={classNames('container', styles.container)}>
          <h1 className={styles.title}>{t('404Page.heroTitle')}</h1>
          <h2 className={styles.description}>{t('404Page.heroText')}</h2>
          <Search className={styles.search} />
        </div>
      </header>
    </div>
  );
};
