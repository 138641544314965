import { QueryFunction, QueryKey } from 'react-query/types/core/types';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { useQuery } from 'react-query';

export type QueryWithInitialDataFn = <TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData>,
  initial: TData | undefined,
  options?: UseQueryOptions<TQueryFnData, TError, TData>) =>
  UseQueryResult<TData, TError> & { current?: TData, hasFreshData: boolean };

export const useQueryWithInitialValue = <TQueryFnData = unknown, TError = unknown,
    TData = TQueryFnData>(
    queryKey: QueryKey,
    queryFn: QueryFunction<TQueryFnData>,
    initial: TQueryFnData,
    options?: UseQueryOptions<TQueryFnData, TError, TData>,
  ) => {
  const queryData = useQuery<TQueryFnData, TError, TData>(queryKey, queryFn, options);
  // If there is data or error, we are fine with showing
  const hasFreshData = !!queryData.data || !!queryData.error;
  const current = queryData.data || initial;
  return { ...queryData, current, hasFreshData };
};
